import "./App.css";
import RouteConfig from "./routes/RouteConfig";

function App() {
  
  return (
    <>
    <RouteConfig />
    </>
  );
}

export default App;
